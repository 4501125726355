.footer {
    width: 100vw; /* 50% of the viewport width */
    height: 10vh;
    position: fixed;
    bottom: 0;
    align-items: center;
    background-color: #1C1C1C;
}

nav ul {
    display: flex;
    justify-content: center;  /* Center the links horizontally */
    list-style-type: none;    /* Remove default bullets */
    padding: 0;
  }
  
  nav ul li {
    margin: 0 15px;           /* Add spacing between the links */
  }
  
  nav ul li a {
    text-decoration: none;     /* Remove underline */
    color: #FAFAFF;              /* Set link color */
  }
  
  nav ul li a:hover {
    color: #DADDD8;               /* Change color on hover */
  }