html, body {
  height: 100%;
  margin: 0;
}

.main {
  width: 50vw; /* 50% of the viewport width */
  height: 70vh; /* 50% of the viewport height */
  display: flex;
  justify-content: center;/**/
  align-items: center;/**/
  /*background-color: lightblue;*/
  text-align: center;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.profilePic {
  position: absolute;
  top: 0;
  left: 3000;
}

.circular-image {
  width: 200px;  /* Set the desired width */
  height: 200px; /* Set the same height for the circle */
  border-radius: 50%; /* This ensures the image is circular */
  object-fit: cover; /* Keeps the image proportional within the circle */
  border: 3px solid #0077b6; /* Optional: Adds a border to match your theme */
}

.logo {
  width: 100px;  /* Set the desired width */
  height: 100px; /* Set the same height for the circle */
}

.name {
  font-family: Arial, Helvetica, sans-serif;
}

.about {
  position: absolute;
  top: 60%;
  left: 3000;
}

.links {
  position: absolute;
  top: 80%;
  left: 3000;
  height: 50px;
  width: 200px;
  display: flex;
  justify-content: space-around;
}

.github-button {
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  vertical-align: top;
  background-image: url('./Home/github-logo-transparent.png');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 20px 20px;
  background-color: white;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.github-button:hover {
  background-color: #50A0E6;
  color: white;
}

.linkedin-button {
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  vertical-align: top;
  background-image: url('./Home/linkedin-logo-transparent.png');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 20px 20px;
  background-color: white;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.linkedin-button:hover {
  background-color: #50A0E6;
  color: white;
}


.mail-button {
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  vertical-align: top;
  background-image: url('./Home/email-icon.png');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 20px 20px;
  background-color: white;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.mail-button:hover {
  background-color: #50A0E6;
  color: white;
}
