
.Intro {
    width: 100vw;
    height: 10vh;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    background-color: #FAFAFF;
    justify-content: center;
}

.Title {
    color: #1C1C1C;
}

.Project-Collection {
    width: 80vw;
    height: 550px;
    position: fixed;
    top: 15vh;
    left: 10vw;
    display: flex;
    flex-wrap: wrap;        /* Allows wrapping to the next line */
    gap: 10px; 
    align-items: center;
    justify-content: space-around;
    background-color: #ECEBE4;
    overflow-y: auto; /* Enable vertical scrolling */
}

.Project {
   text-align: center;
    vertical-align: text-top;
    justify-content: space-around;
    overflow-y: visible; /* Enable vertical scrolling */
}


.Projects-links {
    text-decoration: none;     /* Remove underline */
    color: #000000; 
}
  
.Projects-links:hover {
    color: #CE2029;               /* Change color on hover */
}

.Project-image {
    width: 500px;
    height: 200px;
    border: 3px solid #404E4D;  /*# */
}

.Project-image:hover {
    
    border: 3px solid #CE2029;
}

.Chair-image {
    width: 400px;
    height: 500px;
    border: 3px solid #404E4D;  /*# */
}